import React, { useState } from "react";
import "./Testimonial.css"; // For styling

// Testimonial Data
const testimonials = [
  {
    name: "Mark Magellan",
    title: "IDEO alum | Professor | Strategist & Design Leader",
    image: "https://media.licdn.com/dms/image/v2/D4E03AQFbXLPSBwe8Cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680280888519?e=1732752000&v=beta&t=o_BtX11LklktqN2smZFgXdDG9rNMw7P6EPoNZ8JmlT8", // Placeholder image
    text: "Creativity, for me, is often synonymous with energy. Raymond has a way of lighting up every room he steps into through his presence — he brings a calm, a curiosity, and a spark that puts people at ease and unlocks their strengths. He’s a harmonizer and a natural facilitator. This, above all else, is what I look for in creative leadership — those who can ground and light up a room with equal dexterity. That's Raymond.",
  },
  {
    name: "Adedolapo Olisa",
    title: "Lead Software Engineer, Cox Automotive Inc.",
    image: "https://media.licdn.com/dms/image/v2/D5603AQFRTzH-vCYlAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684882548684?e=1732752000&v=beta&t=2rNKyyCIVqcoqrsqwjGxddVwNwU3jKTW99szZvOuAfI", // Placeholder image
    text: "Some of the easiest recommendations are for people that have touched you emotionally. Such is the impact of Raymond's presence in any space he occupies. I can recommend his design skills, leadership skills, soft skills, and many more and I will but underneath the skills is the character. He made time to contribute to marketing, culture, and other important but hard-to-quantify areas of the business. His work ethic and drive for continuous improvement are contagious. His impact on culture cannot be overstated. I give Raymond my highest recommendation as a human and certainly as a leader, future employee.",
  },
  {
    name: "Doowuese Igbazua",
    title: "Interaction Designer",
    image: "https://media.licdn.com/dms/image/v2/C4D03AQH4zhGQmmxiCQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1648484495665?e=1732752000&v=beta&t=U9pdy8CZ-mZtsEbvgOyvmYmsEi7V3flBhrXps9fn0Lg", // Placeholder image
    text: "Raymond is the exact definition of a person with integrity. It shows in his work, creativity and leadership. He stays ever curious, learning new skills and working his way to be well rounded in all things design and entrepreneurship. Raymond has outstanding work ethics, as he would usually say “I do it because I love it, not because it’s a job”. This is the drive he brings to his work — not just as a designer, but as a leader who embodies grace, passion for growth, and a commitment to mentorship.",
  },
];

const Testimonial = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);

  // Handle the click event to switch testimonials
  const handleTabClick = (index) => {
    setSelectedTestimonial(index);
  };

  return (
    <div className="testimonial-container">
      <div className="testimonial-tabs">
        {testimonials.map((testimonial, index) => (
            <div
            key={index}
            className={`testimonial-tab ${
              selectedTestimonial === index ? "active" : "inactive"
            }`}
            onClick={() => handleTabClick(index)}
          >
            <img
              src={testimonial.image}
              alt={`${testimonial.name}'s picture`}
              className="testimonial-image"
            />
          </div>
        ))}
      </div>

      <div className="testimonial-content">
        <p className="pr-aag">
          "{testimonials[selectedTestimonial].text}"
        </p>
        <h4 className="testimonial-name">{testimonials[selectedTestimonial].name}</h4>
        <p className="testimonial-title">{testimonials[selectedTestimonial].title}</p>
      </div>
    </div>
  );
};

export default Testimonial;
