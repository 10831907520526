import React from 'react'
import sunshine from "../Assets/logos/sunbold.svg"
import "../Styles/AboutStyles/About.css"
import Story from '../Components/AboutPage/Story'
import Workhistory from '../Components/AboutPage/Workhistory'
import Skillsset from '../Components/AboutPage/Skillsset'
import raymondmbamd from "../Assets/images/raymondmbamd.jpg"
import { useTheme } from '../Context/Theme'
import { IoSunny } from "react-icons/io5";
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet';
import Navbar from '../Resources/Navbar'
import AboutMe from '../Components/AboutPage/AboutMe'


const About = () => {

  const { dark } = useTheme()
  return (
    <div className={`thoughts-pg ${dark && "darkbg" }`}>
     <Helmet>
     <title>About Me | Raymond Mbam</title>
     <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
   </Helmet>
        <div className='box-ed'>
        <AboutMe />
            <Skillsset />
        </div>
    </div>
  )
}

export default About
