import React, { useState } from 'react';
import './AboutMe.css'; // For styling

// Import images from the src folder
import image1 from '../../Assets/images/museum.jpg';
import image2 from '../../Assets/images/sby.jpg';
import image3 from '../../Assets/images/sby2.jpg';
import image4 from '../../Assets/images/library.jpg';
import image5 from '../../Assets/images/next.jpg';
import image6 from '../../Assets/images/gw-desa.jpg';

// Array of images and alt text
const images = [
  { src: image1, alt: 'Image 1 description' },
  { src: image2, alt: 'Image 2 description' },
  { src: image3, alt: 'Image 3 description' },
  { src: image4, alt: 'Image 4 description' },
  { src: image5, alt: 'Image 5 description' },
  { src: image6, alt: 'Image 6 description' },
];
const AboutMe = () => {
  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  return (
    <div className="aboutme-container">
      {/* Column 1: About Me Text */}
      <div className="aboutme-text">
        <h2>About Me</h2>
        <p>
            Hi, I’m Raymond Mbam, a passionate designer and UX developer with a deep
            love for <span style={{ backgroundColor: '#c0f27f' }}>design systems</span> and
            crafting intuitive digital experiences.
        </p>
        <p>
          I believe that design is a powerful tool for shaping behaviors,
          influencing culture, and transforming perspectives. My journey in
          design has been shaped by curiosity and a constant desire to learn. I
          hold a Master’s degree in Interaction Design from George Washington
          University, where I developed a keen understanding of user-centered
          design principles.
        </p>
        <p>
          I thrive on driving projects from conception to market, constantly
          refining and improving features within a user-centered design
          process. <span style={{ backgroundColor: '#c0f27f' }}>This very website is a reflection of my hands-on experience, built entirely with ReactJS.</span>
        </p>
        <p>
          Beyond my work in design and development, I’m passionate about giving
          back to the community through volunteering. I’m always seeking ways
          to grow and expand my knowledge, and I view every project as an
          opportunity to do just that.
        </p>
      </div>

      {/* Column 2: Image Carousel */}
      <div className="aboutme-carousel">
        {/* Enlarged Image */}
        <div className="carousel-large-image">
          <img
            src={images[selectedImage].src}
            alt={images[selectedImage].alt}
            className="enlarged-image"
          />
        </div>

        {/* Thumbnails */}
        <div className="carousel-thumbnails">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
              className={`thumbnail ${selectedImage === index ? 'active' : ''}`}
              onClick={() => handleImageClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutMe;