import React from 'react';
import './Hero.css';
import { useTheme } from '../Context/Theme';
import { PiLightningBold } from "react-icons/pi";
import { GoArrowUpRight } from "react-icons/go";

function HeroSection() {
  const {dark} = useTheme()
  const items = [
    { company: 'Nuubi', project: 'Design Systems & Mobile Design', year: '2024', path: "https://nuubi.app" },
    { company: 'Stelo by Dexcom', project: 'Conversation Design', year: '2024', path: "https://www.stelo.com" },
    { company: 'Alps', project: 'Web App Design', year: '2024', path: "https://tryalps.com" },
    { company: 'Maildrip', project: 'Web App Design', year: '2023', path: "https://maildrip.io" },
    { company: 'Charisol', project: 'Web & Mobile Design', year: '2022', path: "https://charisol.io" } 
];
    return (
        <section className={`hero-section ${dark && "darkbg"}`}>
          <div className="hero-content">
          <div className='otw-ctas'> <PiLightningBold />
                  <div className='otw-btn'><a href="mailto:raymondmbamdaniel@gmail.com">Open to work — Email me </a></div> </div>
            <h1 className={`hero-title ${dark && "darkText"}`}>Raymond Mbam</h1>
            <div>
            <p className="hero-intro">
              I’m a product designer who loves creating 0 — 1 digital products. 
              My roles have spanned product strategy and design, allowing me to 
              steer product visions and craft interfaces, but hey, I find time 
              to contribute to engineering, marketing, and even operations.
              <br/>
              <br/>
              <span style={{ backgroundColor: '#c0f27f' }}>I'm  currently volunteering at an edtech startup, where I am focused on developing their design system and converting them to reusable code.</span>
            </p>

            <div className="portfolio-section">
            <p className="hero-intro" style={{marginBottom: 0, fontWeight: 'bold' }}>Recent Engagements</p>
            {items.map((item, index) => (
                <div key={index} className="portfolio-item">
                    <a href={item.path} target="_blank" rel="noopener noreferrer">
                    <span className={`hero-item ${dark && "darkText"}`}>{item.company}
                    <GoArrowUpRight className="rotate-icon" />
                    </span>
                    </a>
                    <span className="project-info">{item.project} / {item.year}</span>
                </div>
            ))}
          </div>
            </div>
            <div className={`line ${dark && "darkline"}`}></div>
          </div>
        </section>
      );
}

export default HeroSection;
